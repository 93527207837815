[data-amplify-authenticator][data-variation="modal"] {
  background-image: url("../../resources/images/background.png");
  background-color: transparent;
  overflow: hidden;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  margin: 0;
  font-size: 1rem;
  font-weight: 1.5;
  line-height: 1.5;
  color: steelblue;
  background-color: #fff;
}

.home-page {
  text-align: center;
  max-width: 950px;
  margin: 0 auto;
  padding: 10px 25px;
  margin-top: 1px;
}

.signin-page {
  text-align: left;
  /* max-width: 950px; */
  margin: 0 auto;
  padding: 40px 25px;
  margin-left: 5px;
}

.questions {
  text-align: centre;
  max-width: 950px;
  margin: 0 auto;
  padding: 10px 10px;
  margin-top: 0px;
  font-size: 30px;
  font-weight: 500;
}

h1 {
  font-size: 60px;
  color: steelblue;
  height: 50px;
}

h2 {
  font-size: 40px;
  color: steelblue;
  margin-top: auto;
}

.wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 95%;
}
.box {
  flex: 0 0 25%;
  text-align: center;
  height: 415px;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
}

.social-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: bottom;
  flex-direction: column;
}

.social-profile {
  flex: 0 0 50%;
  text-align: center;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.social-profile-img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  height: auto;
  overflow: hidden;
  float: left;
}

.social-profile-img-text {
  float: right;
  font-size: 15px;
}

button {
  margin-top: auto;
}

.col-xx-xx {
  padding: 0 15px;
}

.background {
  background-image: url("../../resources/images/background.png");
  background-color: transparent;
  overflow: hidden;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 30px;
  
}

.WebContainer {
  width: 100%;
  min-width: 1000px;
  height: auto;
}

.header {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  outline: none;
}

.header-signin {
  overflow: hidden;
  padding: 10px 20px;
  outline: none;
  display: flex;
  flex-direction: column;
  /* padding: auto; */
  text-align: center;
}

.header-centre h {
  top:0;
  display: inline-flex;
  color: steelblue;
  text-decoration: none;
  font-size: 30px;
  text-align: center;
  margin-right: 150px;
  align-content: center;
  height: 0;
}

.header-centre-wrapper {
  text-align: center;
  float: none;
  position: relative;
  align-items: center;
}

.header a {
  float: left;
  color: steelblue;
  text-align: center;
  padding: 2px;
  text-decoration: none;
  font-size: 25px;
  font-weight: bold;
  text-decoration: underline;
  line-height: 40px;
  border: none;
}

.header a.logo {
  font-size: 5px;
  display: block;
  font-weight: bold;
  float: left;
  position: absolute;
  left: 0;
  top: 0;
  max-width: 230px;
  max-height: 95px;
  width: auto;
  height: auto;
}

.header a:hover {
  background-color: #ddd;
  color: black;
}

.header-right h {
  float: right;
  display: block;
  color: #5555dd;
  text-decoration: none;
  font-size: 30px;
  text-align: right;
}

.header-right-wrapper {
  text-align: right;
  float: right;
}

.button-wrapper {
  text-align: center;
  border: none;
  display:flexbox;
  vertical-align: top;
  align-content: center;
  height: 50px;
}

.button-wrapper-wave {
  text-align: center;
  border: none;
  vertical-align: middle;
  align-content: center;
  margin-bottom: 100px;
}


.button-wrapper-signout {
  text-align: center;
  top: 0;
  right: 0;
}

.signout-button {
  width: 20%;
  top: 0;
  right: 0;
  border-radius: 500px;
  background-color: #5555dd;
  text-decoration: none;
  font-size: 25px;
  text-align: center;
  align-self: center;
  padding: 10px 20px;
  display: inline-block;
  position: absolute;
  color: white;
  font-weight: bold;
  margin: 0 auto;
}

.confirm-button {
  width: 50%;
  border-radius: 500px;
  background-color: #5555dd;
  text-decoration: none;
  font-size: 25px;
  text-align: center;
  align-self: center;
  padding: 10px 20px;
  display: inline-block;
  color: white;
  font-weight: bold;
  margin: 0 auto;
}

.confirm-button-wrapper {
  text-align: center;
  border: none;
  vertical-align: middle;
  align-content: center;
  margin-top: 7%;
}

.amplify-button{
vertical-align: middle;
display: inline-block;
text-align: center;
}
.amplify-field-group__control{
  vertical-align: middle;
  display: inline-block;
}

.signup-button {
  width: 20%;
  height: 1.5em;
  /* margin: 2em auto; */
  border-radius: 300px;
  background-color: #5555dd;
  vertical-align: middle;
  text-decoration: none;
  font-size: 25px;
  text-align: center;
  align-self: center;
  padding: 10px 20px;
  display: inline-block;
  color: white;
  font-weight: bold;
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.datepicker-date-input-container {
    width: 150%;
}

.datepicker-date-input-field {
    width: 15rem;
    font: inherit;
    border: inherit;
  }

  .card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 16px 32px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    position: relative;
    align-self: center;
    display: inline-flexbox;
  }
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 32px 64px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */
  .container {
    padding: 2px 16px;
  }

.custom-dropdown {
    position: relative;
    display: inline-block;
    color: pointer;
    text-align: left;
    font-size: 25px;
    border: 1px solid;
    height: 40px;
    width: 400px;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #D2D7E7;
    background-color: #fff;
    cursor: pointer;
  }
  